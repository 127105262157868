import Footer from "../components/layout/Footer";
import Header from "../components/layout/Header";
import React from "react";

export interface LayoutDefaultProps {
  children: React.ReactChildren | React.ReactChildren[];
}

const LayoutDefault = ({ children }: LayoutDefaultProps) => (
  <React.Fragment>
    <Header />
    <main className="site-content">{children}</main>
    <Footer className="illustration-section-06" />
  </React.Fragment>
);

export default LayoutDefault;
