import React from "react";
import Subpage from "../components/sections/Subpage";
import * as privacyPage from "../data/pages/privacy";

const Privacy = () => {
  return (
    <React.Fragment>
      <Subpage data={privacyPage?.privacy} />
    </React.Fragment>
  );
};

export default Privacy;
