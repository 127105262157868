import React from "react";
import Subpage from "../components/sections/Subpage";
import * as thanksPage from "../data/pages/thanks";

const Coming = () => {
  return (
    <React.Fragment>
      <Subpage data={thanksPage?.thanks} />
    </React.Fragment>
  );
};

export default Coming;
