import React from "react";
import Button from "../components/elements/Button";
import GenericSection from "../components/sections/GenericSection";
import HeroSplit from "../components/sections/HeroSplit";
import Image from "../components/elements/Image";
import ReactMarkdown from "react-markdown";
import classNames from "classnames";
import { Identity } from "../components/elements/Identity";
import * as homePage from "../data/pages/home";
import ImageLink from "../components/elements/ImageLink";
import openZendesk from "../utils/open-zendesk";

const Home = () => {
  return (
    <React.Fragment>
      <HeroSplit data={homePage?.hero} hasBgColor invertColor />
      <GenericSection hasBgColor className="gradient-blue">
        {homePage && (
          <div
            className="desc container center-content"
            id="/about"
            style={{ paddingBottom: '2rem' }}
          >
            <div className="ta-c" style={{ width: 958, color: '#FFF' }}>
              <h2 style={{ color: '#FFF' }}>
                {homePage?.description.title.title}
              </h2>
              <div className="m-0">
                <ReactMarkdown source={homePage?.description.body} />
              </div>
            </div>
          </div>
        )}
      </GenericSection>
      <GenericSection>
        {homePage && (
          <div className="container center-content" id="/your-identity">
            <h2>
              {homePage?.need.content.title} <br></br>
            </h2>
            <div
              className="m-0"
              style={{ margin: '0 auto', maxWidth: 958, color: '#000' }}
            >
              <ReactMarkdown source={homePage?.need.content.body} />
            </div>
            <div
              style={{
                display: `flex`,
                flexDirection: `column`,
                width: `100%`,
                alignItems: `center`,
              }}
            >
              {homePage?.need.identities.map((identity: any, idx: number) => (
                <div style={{ width: '100%' }}>
                  <Identity key={identity.id} identity={identity} />
                  {idx !== homePage?.need?.identities?.length - 1 && (
                    <div
                      style={{
                        display: `inline-block`,
                        borderRight: '2px dashed #d8d8d8',
                        width: 1,
                        height: 32,
                      }}
                    ></div>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
      </GenericSection>

      <GenericSection
        noContainer
        className="gradient2 clip mobile-hide-clip pb-32"
      >
        <div className="container" id="/world">
          <h2 className="ta-c w-full text-color-light pt-32 pb-16">
            {homePage.world?.header ??
              'Everything in your world, all in one place.'}
          </h2>
        </div>
        <div className="flex-row flex-wrap p-16 flex-justify-c">
          {homePage?.world?.places?.map((place: any, idx: number) => {
            return (
              <>
                <div key={place.id} className="mb-16 place">
                  <div className={`ta-c body`}>
                    <div className="mb-16 place-title">
                      <p className="fw-600 mb-0">{place.title}</p>
                    </div>
                    <p style={{ fontStyle: 'italic', fontWeight: 200 }}>
                      {place.subtitle}
                    </p>
                    <ReactMarkdown source={place.body} />
                  </div>
                </div>
                {idx !== homePage?.world?.places?.length - 1 && (
                  <div className="place-separator" />
                )}
              </>
            );
          }) ?? null}
        </div>
        <div className="flex-row flex-wrap">
          <div
            className="advantage-col"
            style={{ padding: 0, display: 'flex', alignItems: 'flex-end' }}
          >
            <Image
              className="advantage-image"
              src={homePage?.terms.image.url}
            />
          </div>
          <div className="advantage-col">
            <h2 className="ta-l text-color-light pt-16 pb-16">
              {homePage?.terms.title.title}
            </h2>
            {homePage?.terms.advantages &&
              homePage?.terms.advantages.map(
                (advantages: any, index: number) => {
                  return (
                    <div key={index} className="mb-16 advantage-image-holder">
                      <div className="ml-16">
                        <p className="fw-700 mb-0">{advantages.title}</p>
                        <ReactMarkdown source={advantages.body} />
                      </div>
                    </div>
                  );
                }
              )}
          </div>
        </div>
      </GenericSection>
      <GenericSection>
        <div className="flex-column-center" id="/how-proof-works">
          <h1>{homePage?.works.title}</h1>
          <div className="m-32">
            {homePage?.works.steps.map((step: any, index: number) => {
              const stepNumber = `0${index + 1}`;
              const swapped = index % 2 === 0;
              const holderClasses = classNames(
                'holder ta-l full',
                swapped ? 'swap' : ''
              );
              const contentClasses = classNames(
                'mw-400 pb-32',
                swapped ? 'pl-32' : 'pr-32'
              );

              return (
                <div key={index}>
                  <div className="text-color-low step">
                    STEP <h2 className="m-0">{stepNumber}</h2>
                  </div>
                  <div className={holderClasses}>
                    <div className={contentClasses}>
                      <h4 className="fw-600">{step.content.title}</h4>
                      <span className="text-color-mid fs-i">
                        <ReactMarkdown source={step.content.body} />
                      </span>
                    </div>

                    <div className="border"></div>
                    <div className="mw-400 pb-32 pl-32 pr-32">
                      <Image
                        className={swapped ? undefined : 'step-image'}
                        src={step.content.image.url}
                        alt={step.content.image.alternativeText}
                      />
                    </div>
                    <div className="tip mb-32">
                      <div className="tip-data mw-700 full ta-c">
                        <b>{step.tip.title}</b>
                        <div>
                          <ReactMarkdown source={step.tip.body} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </GenericSection>
      <GenericSection className="gradient2 clip2">
        <div className="container" id="/support">
          <div className="flex-row flex-justify-sb flex-wrap flex-align-c">
            <div className="cta-slogan">
              <h2 className="m-0 text-color-light">
                {homePage?.cta.content.title}
              </h2>
              <br></br>
              <div className="mw-700 full flex-row flex-wrap">
                <div className="mw-400">
                  <ReactMarkdown>{homePage?.cta.content.body}</ReactMarkdown>
                </div>
              </div>
            </div>
            <div className="cta-action">
              <Button className="button-cta" onClick={openZendesk}>
                {homePage?.cta.button.text}
              </Button>
            </div>
          </div>
        </div>
      </GenericSection>
      <GenericSection>
        <div className="container ta-c">
          <h1 className="pb-16">{homePage?.download?.title}</h1>
          <div
            style={{ gap: '0.5rem' }}
            className="full flex-row flex-justify-c flex-wrap pb-32"
          >
            {homePage?.download?.button?.map(
              ({ link: href, url: src, ...props }: any) => (
                <ImageLink
                  {...props}
                  href={href}
                  src={src}
                  className="button-download"
                />
              )
            )}
          </div>
        </div>
      </GenericSection>
      <GenericSection
        className="clip4"
        style={{
          backgroundColor: 'rgb(247, 247, 247)',
        }}
        noContainer
        noInner
      >
        <div
          id="/about-proof"
          style={{
            backgroundImage: `url(${require('../assets/images/identa-logo-lg.png')})`,
            backgroundPositionY: '-12%',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <div
            className="ta-c"
            style={{
              background:
                'linear-gradient(180deg, rgba(255, 255, 255, 0.5) 30%, rgba(255, 255, 255, 1) 100%)',
              padding: '5rem 0',
            }}
          >
            <div className="container">
              <h1 className="m-0">{homePage?.about?.header?.title}</h1>
              <h4 style={{ fontStyle: 'italic' }}>
                {homePage?.about?.header?.subtitle}
              </h4>
              <div
                className="mw-700"
                style={{ margin: '0 auto', color: '#000' }}
              >
                <ReactMarkdown source={homePage?.about?.content_1} />
              </div>
              <div>
                <Image
                  src={homePage?.about?.logo?.url}
                  width={256}
                  style={{ display: 'inline' }}
                  alt={homePage?.about?.logo?.alternativeText}
                />
              </div>
              <div
                className="mw-700"
                style={{ margin: '0 auto', color: '#000' }}
              >
                <ReactMarkdown source={homePage?.about?.content_2} />
              </div>
              <div className="cta-action">
                <Button
                  tag="a"
                  color="dark"
                  href={homePage?.about?.button?.link}
                >
                  {homePage?.about?.button?.text}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </GenericSection>
    </React.Fragment>
  );
};

export default Home;
