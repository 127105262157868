import { HomeCta } from "../../../types";

export const cta: HomeCta = {
  content: {
    title: "Are you a developer?",
    body: "Review our API docs and interoperable wallet SDK, and start building this experience directly into your app.",
  },
  button: {
    text: "CONTACT US",
    link: "#/wait-list",
  },
};
