import { HomeWorld } from "../../../types";

export const world: HomeWorld = {
  title: "Everything in your world, all in one place",
  places: [
    {
      title: "i_live",
      subtitle: "Move through life, simply.",
      body: "Enjoy milestones such as purchasing a home, getting married, or growing your family without the need for all the in-person visits, paper based processes, and administrative tasks that mark life's biggest moments.",
      id: "1",
    },
    {
      title: "i_work",
      subtitle: "Be highly efficient.",
      body: "Consolidate all your most sensitive information such as banking credentials, income verification, or job history and unlock new opportunities for growth, whether that be for instant applications for jobs, loans, or health benefits – **proof** makes it easy.",
      id: "2",
    },
    {
      title: "i_explore",
      subtitle: "Move freely with confidence.",
      body: "Always be who you are as you make connections with your communities or complete transactions online, and wander freely knowing your information is safe, secure and in your hands.",
      id: "3",
    },
  ],
  button: {
    text: "JOIN THE WAITLIST",
  },
};
