import { HomeHero } from "../../../types";

export const hero: HomeHero = {
  background: {
    alternativeText: "",
    caption: "",
    url: "images/hero-bg.png",
  },
  title: {
    title: "proof",
    subtitle: "It's who you are",
  },
  button: [
    {
      link: "https://apps.apple.com/us/app/proof/id1536600078",
      url: "images/apple.svg",
      alternativeText: "",
      caption: "",
    },
    {
      link: "https://play.google.com/store/apps/details?id=com.atb.myproof",
      url: "images/google-play.svg",
      alternativeText: "",
      caption: "",
    },
  ],
  image: {
    alternativeText: "",
    caption: "",
    url: "images/logo.png",
  },
};
