import { HomeTerms } from "../../../types";

export const terms: HomeTerms = {
  title: {
    title: "Govern your identity, on your terms",
  },
  image: {
    alternativeText: "",
    caption: "",
    url: "images/proof-govern.png",
  },
  advantages: [
    {
      title: "Own your identity",
      body: "Expect easy onboarding, single sign-on, and instant account verification with our trusted partners.",
    },
    {
      title: "Own the information you share",
      body: "Establish secure, dependable connections with all institutions you choose to interact with day-to-day from your authorized device.",
    },
    {
      title: "Seamless interactions",
      body: "Self-sovereign identity (SSI) means you are in control of your data. It's that simple.",
    },
    {
      title: "Secure, reliable storage",
      body: "**proof** maintains rigorous global security standards and advanced encryption to protect your information. Be confident that your information will remain secure and accurate under your ownership.",
    },
  ],
};
