import React, { Key, MutableRefObject } from "react";

import LogoPartial from "./partials/Logo";
import { NavLink } from "react-router-dom";
import SmoothScroll from "../elements/SmoothScroll";
import classNames from "classnames";

type NAV_POSITIONS = "right" | "center";

export type NavItem = {
  to: string;
  displayText: string;
};

export interface HeaderProps {
  active?: boolean;
  navPosition?: NAV_POSITIONS;
  hideNav?: boolean;
  hideSignin?: boolean;
  bottomOuterDivider?: boolean;
  bottomDivider?: boolean;
  className?: string;
}
class Header extends React.Component<HeaderProps> {
  state = {
    isActive: false,
  };

  _nav: React.MutableRefObject<HTMLDivElement> =
    React.createRef() as MutableRefObject<HTMLDivElement>;

  _hamburger: React.MutableRefObject<HTMLButtonElement> =
    React.createRef() as MutableRefObject<HTMLButtonElement>;

  headerItems: NavItem[] = [
    {
      to: "/your-identity",
      displayText: "Your identity",
    },
    {
      to: "/how-proof-works",
      displayText: "How proof works",
    },
    {
      to: "/about-proof",
      displayText: "About proof",
    },
  ];

  componentDidMount() {
    this.props.active && this.openMenu();
    document.addEventListener("keydown", this.keyPress);
    document.addEventListener("click", this.clickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.keyPress);
    document.addEventListener("click", this.clickOutside);
    this.closeMenu();
  }

  openMenu = () => {
    document.body.classList.add("off-nav-is-active");
    this._nav.current.style.maxHeight = this._nav.current.scrollHeight + "px";
    this.setState({ isActive: true });
  };

  closeMenu = () => {
    document.body.classList.remove("off-nav-is-active");
    this._nav.current && (this._nav.current.style.maxHeight = "");
    this.setState({ isActive: false });
  };

  keyPress = (e: any) => {
    this.state.isActive && e.keyCode === 27 && this.closeMenu();
  };

  clickOutside = (e: any) => {
    if (!this._nav.current) return;
    if (
      !this.state.isActive ||
      this._nav.current.contains(e.target) ||
      e.target === this._hamburger.current
    )
      return;
    this.closeMenu();
  };

  render() {
    const {
      className,
      active,
      navPosition,
      hideNav,
      hideSignin,
      bottomOuterDivider,
      bottomDivider,
      ...props
    } = this.props;

    const classes = classNames(
      "site-header",
      bottomOuterDivider && "has-bottom-divider",
      className
    );

    const pathname = window?.location?.pathname;

    return (
      <header {...props} className={classes}>
        <div>
          <div className="container-xl">
            <div
              className={classNames(
                "site-header-inner",
                bottomDivider && "has-bottom-divider"
              )}
            >
              <div>
                <LogoPartial
                  width="132"
                  variant={
                    pathname === "/privacy-policy" ||
                    pathname === "/terms-of-use"
                      ? "dark"
                      : "white"
                  }
                />
              </div>
              {!hideNav && (
                <React.Fragment>
                  <button
                    ref={this._hamburger}
                    className="header-nav-toggle"
                    onClick={
                      this.state.isActive ? this.closeMenu : this.openMenu
                    }
                  >
                    <span className="screen-reader">Menu</span>
                    <span className="hamburger">
                      <span className="hamburger-inner" />
                    </span>
                  </button>
                  <nav
                    ref={this._nav}
                    className={classNames(
                      "header-nav",
                      this.state.isActive && "is-active"
                    )}
                  >
                    <div className="header-nav-inner">
                      <ul
                        className={classNames(
                          "list-reset text-xs",
                          navPosition && `header-nav-${navPosition}`
                        )}
                      >
                        {this.headerItems.map(
                          (headerItem: NavItem, index: Key) => {
                            return (
                              <li key={headerItem.to}>
                                <SmoothScroll
                                  onLinkClick={this.closeMenu}
                                  to={headerItem.to}
                                  duration={1500}
                                >
                                  <NavLink to="" activeClassName="is-active">
                                    {headerItem.displayText}
                                  </NavLink>
                                </SmoothScroll>
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>
                  </nav>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
