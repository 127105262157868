import { HomeNeed } from "../../../types";

export const need: HomeNeed = {
  content: {
    title: "What makes my identity, me?",
    body: "Your identity is complex; it consists of a collection of elements that all uniquely point back to how you identify yourself, how you are represented, and how you transact with the world around you.\n\n**proof** gives you the power to reduce that complexity and consolidate all your personal information by requesting your data to be given back to you as verifiable digital credentials – making you the center of your own universe.\n\n\n#### The dimensions of identity ",
  },
  identities: [
    {
      left_title: "Tens",
      left_body: "Name, age, birthplace",
      image: [
        {
          alternativeText: "",
          caption: "",
          url: "images/proof-identity-tens.png",
        },
      ],
      right_title: "Me as an individual",
      right_body: "*Unique identifiers that make me, me!*",
    },
    {
      left_title: "Hundreds",
      left_body:
        "Healthcare, SIN, passport, medical records, employment history, financial statements",
      image: [
        {
          alternativeText: "",
          caption: "",
          url: "images/noun-verified.png",
        },
      ],
      right_title: "How I'm represented",
      right_body: "*Digital or physical identifier provided and authenticated*",
    },
    {
      left_title: "Millions",
      left_body:
        "Likes, comments, shares, usernames, accounts, browsing history, cloud storage, location tracking, text messages",
      image: [
        {
          alternativeText: "",
          caption: "",
          url: "images/proof-identity-mill.png",
        },
      ],
      right_title: "My Interactions",
      right_body: "*Purchases, social interactions and participations*",
    },
  ],
};
