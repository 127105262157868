import { Link } from "react-router-dom";
import React from "react";
import classNames from "classnames";
import { Logo } from "../../elements/Logo";

const LogoPartial = ({ className, variant, width, height, ...props }: any) => {
  const classes = classNames("brand", className);

  return (
    <div {...props} className={classes}>
      <h1 className="m-0">
        <Link to="/">
          <Logo variant={variant} width={width} height={height} />
        </Link>
      </h1>
    </div>
  );
};

export default LogoPartial;
