import React from "react";
import { SectionSharedProps } from "../../utils/SectionProps";
import classNames from "classnames";

interface GenericSectionProps extends SectionSharedProps {
  children: any;
  id?: string;
  data?: any;
  className?: string;
  noContainer?: boolean;
  style?: any;
  noInner?: boolean;
}
const GenericSection = ({
  className,
  children,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  noContainer,
  noInner,
  ...props
}: GenericSectionProps) => {
  const outerClasses = classNames(
    "section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const containerClasses = classNames(!noContainer && "container");

  return (
    <section {...props} className={outerClasses}>
      <div className={containerClasses}>
        <div className={noInner ? undefined : innerClasses}>{children}</div>
      </div>
    </section>
  );
};

export default GenericSection;
