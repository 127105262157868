import React, { MutableRefObject, useRef } from "react";

export interface ImageProps {
  src: any;
  width?: number;
  height?: number;
  alt?: string;
  className?: string;
  style?: any;
}

const Image = ({ className, src, width, height, alt, style }: ImageProps) => {
  const imageRef: MutableRefObject<HTMLImageElement> = useRef(
    document.createElement("img")
  );

  return (
    <img
      ref={imageRef}
      className={className}
      src={src}
      width={width}
      height={height}
      style={style}
      alt={alt}
    />
  );
};

export default Image;
