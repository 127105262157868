/**
 * Keeping this as a class for now to keep scroll animations working.
 *
 * Turning this into a function requires us to alter the ref on the ScrollReveal which
 * causes pages scroll animations to not work and show no content.
 */

import { RouteComponentProps, Switch, withRouter } from 'react-router-dom';

import { ReactGA } from './utils/analytics/ReactGA';
import AppRoute from './utils/AppRoute';
// Views
import ComingSoon from './views/ComingSoon';
import Home from './views/Home';
import Thanks from './views/Thanks';
// Layouts
import LayoutDefault from './layouts/LayoutDefault';
import Privacy from './views/Privacy';
import React from 'react';
import ScrollReveal from './utils/ScrollReveal';
import Terms from './views/Terms';

class App extends React.Component<RouteComponentProps> {
  private scrollRevealRef: React.RefObject<ScrollReveal>;

  constructor(props: RouteComponentProps) {
    super(props);
    this.scrollRevealRef = React.createRef();
  }

  componentDidMount() {
    document.body.classList.add('is-loaded');
    this.scrollRevealRef.current?.init();
  }

  // Route change
  componentDidUpdate(prevProps: any) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.scrollRevealRef.current?.init();
    }
  }

  render() {
    return (
      <ReactGA>
        <ScrollReveal
          ref={this.scrollRevealRef}
          children={() => (
            <Switch>
              <AppRoute
                exact
                path="/"
                component={Home}
                layout={LayoutDefault}
              />
              <AppRoute
                exact
                path="/terms-of-use"
                component={Terms}
                layout={LayoutDefault}
              />
              <AppRoute
                exact
                path="/privacy-policy"
                component={Privacy}
                layout={LayoutDefault}
              />
              <AppRoute
                exact
                path="/coming-soon"
                component={ComingSoon}
                layout={LayoutDefault}
              />
              <AppRoute
                exact
                path="/thanks"
                component={Thanks}
                layout={LayoutDefault}
              />
            </Switch>
          )}
        />
      </ReactGA>
    );
  }
}

export default withRouter((props) => <App {...props} />);
