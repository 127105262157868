import { HomeAbout } from "../../../types";

export const about: HomeAbout = {
  header: {
    title: "About proof",
    subtitle: "The new standard for digital identity.",
  },
  content_1:
    "**proof** was built as part of a revolutionary digital identity portfolio born out of the ATB Innovation Lab, powered by ATB Ventures. \n\nSince 2018, ATB Innovation has grown to a team of researchers, technology practitioners, data science experts, story tellers, and business development professionals who all share a common passion for technology and engineering innovation that is built for scale.\n",
  content_2:
    "**Oliu** is **proof's** business counterpart and was built with the powerful idea that we can improve the digital identity ecosystem for both businesses and users alike, providing a new decentralized and secure solution that brings identity for all.",
  logo: {
    alternativeText: "",
    caption: "",
    url: "",
  },
  button: {
    text: "LEARN MORE ABOUT OLIU",
    link: "https://oliu.id",
  },
};
