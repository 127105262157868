import React, { useEffect } from 'react';
import { initGA, logPageView } from './analytics';

declare global {
  interface Window {
    GA_INITIALIZED: boolean;
  }
}

export function ReactGA(props: any) {
  useEffect(() => {
    if (!window.GA_INITIALIZED) {
      initGA();
      window.GA_INITIALIZED = true;
    }
    logPageView();
  }, []);
  return <div>{props.children}</div>;
}
