import { HomeDownload } from "../../../types";

export const download: HomeDownload = {
  title: "Download proof",
  button: [
    {
      link: "https://apps.apple.com/us/app/proof/id1536600078",
      url: "images/apple.svg",
      alternativeText: "",
      caption: "",
    },
    {
      link: "https://play.google.com/store/apps/details?id=com.atb.myproof",
      url: "images/google-play.svg",
      alternativeText: "",
      caption: "",
    },
  ],
};
