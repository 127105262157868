import React, { ReactNode } from 'react';

import classNames from 'classnames';

type BUTTON_SIZES = 'sm';

export interface ButtonProps extends React.Props<ButtonProps> {
  tag?: string;
  color?: string;
  size?: BUTTON_SIZES;
  loading?: boolean;
  wide?: boolean;
  wideMobile?: boolean;
  disabled?: boolean;
  className?: string;
  children: ReactNode;
  type?: string;
  href?: string;
  name?: string;
  onClick?: () => void;
}

const Button = ({
  className,
  tag = 'button',
  color = 'primary',
  size,
  name,
  loading = false,
  wide = false,
  wideMobile = false,
  disabled = false,
  href,
  type,
  children = null,
  ...props
}: ButtonProps) => {
  const classes: string = classNames(
    'button',
    color && `button-${color}`,
    size && `button-${size}`,
    loading && 'is-loading',
    wide && 'button-block',
    wideMobile && 'button-wide-mobile',
    className
  );

  // TODO: address this Component any type
  const Component: any = tag;
  return (
    <Component
      {...props}
      name={name}
      className={classes}
      disabled={disabled}
      type={type}
      href={href}
    >
      {children}
    </Component>
  );
};

export default Button;
