import Image from '../elements/Image';
import Logo from './partials/Logo';
import React from 'react';
import classNames from 'classnames';

const Footer = ({ className, topOuterDivider, topDivider, ...props }: any) => {
  const dateYear = new Date().getFullYear();

  const classes = classNames(
    'site-footer invert-color',
    topOuterDivider && 'has-top-divider',
    className
  );

  return (
    <footer {...props} className={classes}>
      <div className="container">
        <div
          className={classNames(
            'site-footer-inner',
            topDivider && 'has-top-divider'
          )}
        >
          <div className="footer-top text-xxs mb-32">
            <div className="footer-blocks">
              <div className="footer-block">
                <Logo className="mb-16" />
              </div>
              <div className="footer-block">
                {/** <div className="footer-block-title">Support</div>
                <ul className="list-reset mb-0">
                  <li>
                    <a href="/">Help Center</a>
                  </li>
                  <li>
                    <a href="/">FAQs</a>
                  </li>
                </ul>*/}
              </div>
              <div className="footer-block">
                <div className="footer-block-title">Legal</div>
                <ul className="list-reset mb-0">
                  <li>
                    <a href="/privacy-policy">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="/terms-of-use">Terms of Use</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="pt-32 flex-row flex-justify-sb flex-align-c flex-wrap">
            <div>
              <a href="https://atbventures.com">
                <Image
                  height={22}
                  width={210}
                  src={require('../../assets/images/power.png')}
                  alt="Powered by ATB Ventures"
                />
              </a>
            </div>
            <div className="footer-copyright text-color-light ta-r text-xs">
              Proof &copy; {dateYear} All Rights Reserved
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
