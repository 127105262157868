import React from "react";
import Subpage from "../components/sections/Subpage";
import * as comingSoonPage from "../data/pages/coming-soon";

const Coming = () => {
  return (
    <React.Fragment>
      <Subpage data={comingSoonPage?.comingSoon} />
    </React.Fragment>
  );
};

export default Coming;
