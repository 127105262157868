import React from 'react';
import Image, { ImageProps } from './Image';

export interface Props extends ImageProps, Pick<HTMLLinkElement, 'href'> {}

const ImageLink = ({ href, ...props }: Props) => {
  return (
    <a href={href}>
      <Image {...props} />
    </a>
  );
};

export default ImageLink;
