import React, { ReactNode } from 'react';

import classNames from 'classnames';

export interface ButtonProps {
  children: ReactNode;
  to: string;
  duration: number;
  onLinkClick: Function;
  className?: string;
}

const SmoothScroll = ({
  className,
  children,
  to,
  duration,
  onLinkClick,
}: ButtonProps) => {
  const classes: string = classNames(className);

  const easeInOutQuad = (t: number) => {
    return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
  };

  const scrollToEl = (
    startTime: number,
    currentTime: number,
    duration: number,
    scrollEndElemTop: number,
    startScrollOffset: number
  ) => {
    const runtime = currentTime - startTime;
    let progress = runtime / duration;

    progress = Math.min(progress, 1);

    const ease = easeInOutQuad(progress);

    window.scroll(0, startScrollOffset + scrollEndElemTop * ease);
    if (runtime < duration) {
      window.requestAnimationFrame((timestamp) => {
        const currentTime = timestamp || new Date().getTime();
        scrollToEl(
          startTime,
          currentTime,
          duration,
          scrollEndElemTop,
          startScrollOffset
        );
      });
    }
  };

  const smoothScroll = (e: React.MouseEvent) => {
    e.preventDefault();

    const targetId = to;
    const target = document.getElementById(targetId);
    const dur = duration || 1000;

    if (!target) return;

    onLinkClick && onLinkClick();

    window.requestAnimationFrame((timestamp) => {
      const stamp = timestamp || new Date().getTime();
      const start = stamp;

      const startScrollOffset = window.pageYOffset;
      const scrollEndElemTop = target.getBoundingClientRect().top;

      scrollToEl(start, stamp, dur, scrollEndElemTop, startScrollOffset);
    });
  };

  return (
    <a className={classes} href={'#' + to} onClick={(e) => smoothScroll(e)}>
      {children}
    </a>
  );
};

export default SmoothScroll;
