import { HomeWorks } from "../../../types";

export const works: HomeWorks = {
  title: "How it works",
  steps: [
    {
      content: {
        title: "Download proof and verify your identity",
        image: {
          alternativeText: "",
          caption: "",
          url: "images/step-1.png",
        },
        body: "Connect with your trusted partners, like Government entities or financial institutions and request your identity credentials.",
      },
      tip: {
        title: "Did you know",
        image: null,
        body: "**proof** uses advanced encryption to ensure your identity data is safe. This type of encryption is used everyday for sensitive services like ATMs and storing your financial information. To any person or device without the required key, your data is scrambled and of no use.",
      },
    },
    {
      content: {
        title: "Connect with companies and exchange credentials",
        image: {
          alternativeText: "",
          caption: "",
          url: "images/step-2.png",
        },
        body: "Securely connect with other institutions and service providers in order to share, collect, and create new credentials for your wallet.",
      },
      tip: {
        title: "Did you know",
        image: null,
        body: "The **proof** wallet can securely hold a variety of credentials we use everyday to prove who we are, where we live, or that we qualify for a product or service. Unlike traditional paper or card based credentials, digital credentials can never be lost or used without your knowledge and permission.",
      },
    },
    {
      content: {
        title: "Simplify your life",
        image: {
          alternativeText: "",
          caption: "",
          url: "images/step-3.png",
        },
        body: "Unlock new levels of convenience as you begin to use your new digital credentials for secure sign ons, easy onboarding, or instant applications for products and services like mortgages, virtual health care, and more!",
      },
      tip: {
        title: "Did you know",
        image: null,
        body: "Sharing a credential is as easy as sending a text message, and because your **proof** wallet is always with you, you have the peace of mind of knowing you're prepared for whatever life brings you.",
      },
    },
  ],
};
