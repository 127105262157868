import React from "react";
import Subpage from "../components/sections/Subpage";
import * as termsPage from "../data/pages/terms";

const Terms = () => {
  return (
    <React.Fragment>
      <Subpage data={termsPage.terms} />
    </React.Fragment>
  );
};

export default Terms;
