import React from "react";
import ReactMarkdown from "react-markdown";
import { SectionTilesProps } from "../../utils/SectionProps";
import classNames from "classnames";
import { SubPage } from "../../data/types";

export interface SubpageProps extends SectionTilesProps {
  data: SubPage;
  className?: string;
}

const Subpage = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  data,
  invertColor,
  pushLeft,
  ...props
}: SubpageProps) => {
  const outerClasses = classNames(
    'section center-content p-32',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-32',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames('tiles-wrap m-8 ', pushLeft && 'push-left');

  return (
    <section {...props} className={outerClasses}>
      <div className="container pt-32">
        <div className={innerClasses}>
          <div className="container-xs">
            <h1 className="mt-0 reveal-from-top">{data?.content.title}</h1>
          </div>
          <div className={tilesClasses}>
            <div>
              <div className="tiles-item" style={{ maxWidth: '1000px' }}>
                <div className="tiles-item-inner" style={{ display: 'block' }}>
                  <div className="features-tiles-item-content">
                    <div
                      className="m-0 text-sm reveal-from-top body ta-l text-color-mid a-dark"
                      data-reveal-container=".tiles-item"
                      data-reveal-delay="200"
                      style={{ whiteSpace: 'break-spaces' }}
                    >
                      <ReactMarkdown source={data?.content.body} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Subpage;
