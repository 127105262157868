import Image from "../elements/Image";
import React from "react";
import { SectionSharedProps } from "../../utils/SectionProps";
import classNames from "classnames";
import { Logo } from "../elements/Logo";
import ImageLink from "../elements/ImageLink";

interface HeroSplitProps extends SectionSharedProps {
  data?: any;
  className?: string;
}

const HeroSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  data,
  ...props
}: HeroSplitProps) => {
  const outerClasses = classNames(
    "hero section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "hero-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const splitClasses = classNames(
    "split-wrap",
    invertMobile && "invert-mobile",
    invertDesktop && "invert-desktop",
    alignTop && "align-top"
  );

  const backgroundImage: string = `${data?.background?.url}`;

  return (
    <section
      {...props}
      className={outerClasses}
      style={{
        backgroundImage: "url(" + backgroundImage + ")",
        backgroundAttachment: "fixed",
        minHeight: "96vh",
      }}
    >
      <div className="hero-container container-xl">
        <div className={innerClasses} style={{ zIndex: 1 }}>
          <div className={splitClasses}>
            <div className="hero-figure center-content-mobile reveal-from-bottom pb-32 pt-32">
              <div
                style={{
                  paddingLeft: 16,
                  paddingRight: 16,
                }}
              >
                {data?.image.url && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div className="hero-logo" style={{ maxHeight: 94 }}>
                      <Logo width="270" height="94" />
                    </div>
                    <div className="spacer-32"></div>
                  </div>
                )}
                <h1
                  className="m-0 ta-c"
                  style={{
                    color: "#fff",
                    fontSize: "2rem",
                    textShadow: "0 3px 4px rgba(0, 0, 0, 0.4)",
                  }}
                >
                  {data?.title.subtitle}
                </h1>
                <div className="spacer-32"></div>
                {data?.button && (
                  <div
                    className="flex-row flex-wrap flex-justify-c"
                    style={{
                      gap: "0.35rem",
                    }}
                  >
                    {data?.button?.map(
                      ({ link: href, url: src, ...props }: any) => (
                        <ImageLink
                          {...props}
                          href={href}
                          src={src}
                          className="button-download"
                        />
                      )
                    )}
                  </div>
                )}
              </div>
              <div className="pt-16 pr-16 pl-16">
                <Image
                  src={require("./../../assets/images/phone.png")}
                  alt="Proof"
                  width={324}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSplit;
