import React from 'react';
import Image from './Image';
import ReactMarkdown from 'react-markdown';

export function Identity({ identity }: { identity: any }) {
  return (
    <div
      style={{
        display: 'flex',
        color: '#000',
        lineHeight: 'normal',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        className="ta-r"
        style={{
          lineHeight: 'normal',
          // maxWidth: 196,
          width: '33.33%',
        }}
      >
        <span
          className="m-0"
          style={{
            lineHeight: 'normal',
            fontSize: '1.2rem',
            fontWeight: 'bold',
            display: 'block',
            // marginBottom: `-0.5rem`,
          }}
        >
          {identity.left_title}
        </span>
        <p
          style={{
            fontWeight: 'bold',
            fontSize: '0.5rem',
            maxWidth: '50%',
            marginLeft: 'auto',
          }}
        >
          <ReactMarkdown source={identity.left_body} />
        </p>
      </div>
      <hr
        style={{
          borderTop: '2px dashed rgba(0, 0, 0, 0.15)',
          height: 0,
          background: 'none',
          flexGrow: 1,
          margin: '0 1rem',
        }}
      />
      <Image
        src={identity.image[0].url}
        alt={identity.image[0].alternativeText}
        style={{
          objectFit: 'cover',
          objectPosition: 'center',
          minWidth: 120,
          minHeight: 120,
          maxWidth: 256,
        }}
      />
      <hr
        style={{
          borderTop: '2px dashed rgba(0, 0, 0, 0.15)',
          height: 0,
          background: 'none',
          // width: "11.11%",
          flexGrow: 1,
          margin: '0 1rem',
        }}
      />
      <div
        className="ta-l"
        style={{
          lineHeight: 'normal',
          width: `33.33%`,
        }}
      >
        <span
          className="m-0"
          style={{
            lineHeight: 'normal',
            fontSize: '0.65rem',
            fontWeight: 'bold',
            display: 'block',
          }}
        >
          {identity.right_title}
        </span>
        <div
          style={{
            fontWeight: 'bold',
            fontSize: '0.5rem',
          }}
        >
          <ReactMarkdown source={identity.right_body} />
        </div>
      </div>
    </div>
  );
}
